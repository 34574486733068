<template>
    <div class="">
        <FullCalendar class="crm-calendar"
					  ref="calendar"
					  :options="this.calendarOptions">
            <template v-slot:eventContent='arg'>
                <b>{{ arg.timeText }}</b>
                <i>{{ arg.event.title }}</i>
            </template>
        </FullCalendar>
    </div>
</template>

<script>
    import { defineComponent } from 'vue'
	import '@fullcalendar/core/vdom' // solves problem with Vite
	import FullCalendar from '@fullcalendar/vue3'
	import dayGridPlugin from '@fullcalendar/daygrid'
	import timeGridPlugin from '@fullcalendar/timegrid'
	import interactionPlugin from '@fullcalendar/interaction'
	import { INITIAL_EVENTS, createEventId } from '@/mockData/event-utils'
	import ruLocale from '@fullcalendar/core/locales/ru';
	import listPlugin from '@fullcalendar/list';
	import CommonService from "../../services/CommonService";
	import api from "@/api";
	import {
		DateMarker,
		DateProfile,
		DateRange,
		Dictionary,
		MoreLinkContentArg,
		MoreLinkRootProps,
		Seg
	} from "@fullcalendar/common";
	import {ComponentChildren, RefObject, VNode} from "@fullcalendar/common/vdom";

    export default {
        name: "CalendarComponent",

		components: {
			FullCalendar
		},
		props: {
			inputFilters: {type: Object},
		},
        data() {
            return {
				calendar: null,
				calendarOptions: {
					plugins: [ dayGridPlugin, timeGridPlugin,  interactionPlugin, listPlugin ],

					headerToolbar: {
						left: 'prev,next,today',
						center: 'title',
						right: 'listWeek,dayGridMonth,timeGridWeek,timeGridDay',
					},

                    slotDuration: '01:00:00',

                    slotLabelFormat: {
                        hour: 'numeric',
                        minute: '2-digit',
                        omitZeroMinute: false,
                        meridiem: 'short'
                    },

					eventTimeFormat: { // '14:30'
						hour: '2-digit',
						minute: '2-digit',
						meridiem: false
					},

                    eventClassNames: function(arg) {

                        let classes = [];
						if (arg.event.extendedProps.event_type === 'moderation') {
							classes.push('moderation-event');
						} else if (arg.event.extendedProps.event_type === 'sub_moderation') {
							classes.push('sub-moderation-event');
						} else if (arg.event.extendedProps.event_type === 'call') {
							classes.push('call-event');
						} else if (arg.event.extendedProps.event_type === 'meet') {
							classes.push('meet-event');
						} else if (arg.event.extendedProps.event_type === 'show') {
							classes.push('show-event');
						} else if (arg.event.extendedProps.event_type === 'deal') {
							classes.push('deal-event');
						} else if (arg.event.extendedProps.event_type === 'even') {
							classes.push('even-event');
						} else if (arg.event.extendedProps.event_type === 'birthday') {
							classes.push('birthday-event');
						}

						if (!arg.event.extendedProps.is_viewed) {
							classes.push('is-new-event');
						}

						return classes;
                    },

                    eventDisplay: 'auto',
					
					locale: ruLocale,

					//initialView: 'listWeek',
					initialView: 'dayGridMonth',

					displayEventEnd: false, // показывать окончание события
					displayEventTime: true, // показывать время события
					eventMaxStack: 3, // макс кол-во событий
					eventLimit: 1,

					//noEventsText: String,
					//viewHint: identity,
					//navLinkHint: identity,
					//closeHint: String,
					//timeHint: String,
					//eventHint: String,

					//moreLinkClick: identity,
					moreLinkClassNames: 'fc-crm-more-link', // класс для количества событий
					moreLinkContent: function(arg) { // показ количества событий

						// arg.num
						// arg.text
						// arg.shortText
						// arg.view

						let eventCount = document.createElement('i');
						if (arg.num > 0) {
							eventCount.innerHTML = arg.num
						}

						return { domNodes: [eventCount] }
					},
					moreLinkDidMount: (args) => this.moreLinkDidMount(args),
					//moreLinkWillUnmount: identity,
					moreLinkRootProps: {
						//dateProfile: DateProfile;
						//todayRange: DateRange;
						//allDayDate: DateMarker | null;
						//moreCnt: 3,
						//allSegs: Seg[];
						//hiddenSegs: Seg[];
						//extraDateSpan?: Dictionary;
						//alignmentElRef: RefObject<HTMLElement>;
						//alignGridTop?: boolean;
						//topAlignmentElRef?: RefObject<HTMLElement>;
						//defaultContent?: (hookProps: MoreLinkContentArg) => ComponentChildren;
						//popoverContent: () => VNode;
						//children: MoreLinkChildren;
					},

					/*titleFormat: { // формат показа даты в шапке календаря
						month: 'numeric',
						year: 'numeric',
						day: 'numeric'
					},*/

					eventMinHeight: 80, // мин. высота события
                    nowIndicator: true, // показ текущего времени
                    slotLabelInterval: '1:00:00', // интервал показа времени
					scrollTime: '08:00:00', // с какого времени показывать
                    contentHeight: 500, // высота контента календаря

                    stickyHeaderDates: true,

					editable: false,   // перемещение события по календарю
					selectable: false, // создание нового события при клике на день календаря

					dayMaxEvents: 0, // макс количество для отображения(показывает + еще 2...)
                    allDaySlot: false, // показывать ячейку событий на целый день
					weekends: true, // показывать выходные дни

                    slotEventOverlap: true, // накладывание событий друг на друга
                    
					// events and methods
					//initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
					events: (fetchInfo, successCallback, failureCallback) => this.getCalendarData(fetchInfo, successCallback, failureCallback),
					//select: this.handleDateSelect,
					eventClick: this.handleEventClick,
					eventsSet: this.handleEvents
				},
				currentEvents: []
            };
        },
        methods: {
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			handleWeekendsToggle() {
				console.info('handleWeekendsToggle()');
				this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
			},
			moreLinkDidMount(args) {

				let date = args.el.closest('.fc-day').dataset['date'];
				let is_have_new = false;
				let is_have_viewed = false;
				let is_have_expiried = false;

				if (date) {
					this.currentEvents.forEach((item) => {
						let start_date = item._def.extendedProps.start_date;
						if (start_date == date) {

							if (item._def.extendedProps.is_viewed) {
								is_have_viewed = true
							} else if (!item._def.extendedProps.is_viewed) {
								is_have_new = true
							}

							if (item._def.extendedProps.is_expiried) {
								is_have_expiried = true
							}
						}
					});

					if (is_have_new && !is_have_viewed)
						args.el.classList.add('events-has-new');
					else if (is_have_viewed && is_have_new)
						args.el.classList.add('events-has-read');
					else if (is_have_expiried)
						args.el.classList.add('events-has-expiried');
				}

				/*if (this.states[args.el.closest('.fc-day').dataset['date']] == 'new') {
					args.el.classList.add('events-has-new');
				} else if (this.states[args.el.closest('.fc-day').dataset['date']] == 'read') {
					args.el.classList.add('events-has-read');
				} else if (this.states[args.el.closest('.fc-day').dataset['date']] == 'expiried') {
					args.el.classList.add('events-has-expiried');
				}*/
			},
			/*handleDateSelect(selectInfo) {
				let title = prompt('Please enter a new title for your event')
				let calendarApi = selectInfo.view.calendar
				console.log(calendarApi)
				calendarApi.unselect() // clear date selection
				
				if (title) {
						calendarApi.addEvent({
						id: createEventId(),
						title,
						start: selectInfo.startStr,
						end: selectInfo.endStr,
						allDay: selectInfo.allDay
					})
				}
			},*/
			handleEventClick(clickInfo) {
                //confirm(`Тут будет модалка с информацией о '${clickInfo.event.title}'`);
				let props = clickInfo.event._def.extendedProps;
				console.info('handleEventClick()', {clickInfo, props});
				this.$emit('openEventModal', props);

			},
			handleEvents(events) {
				this.currentEvents = events
			},
			getCalendarData(fetchInfo, successCallback, failureCallback) {

				let filters = {...this.filters, ...{
					//start_date: fetchInfo.start.valueOf(),
					start_date: fetchInfo.startStr,
					//end_date: fetchInfo.end.valueOf(),
					end_date: fetchInfo.endStr,
				}};

				console.info('getCalendarData()', {filters, fetchInfo, successCallback, failureCallback});

				api.get('/common/calendar', {
					params: {
						filters: filters
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getCalendarData()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						successCallback(
							/*Array.prototype.slice.call( // convert to array
								response.data.getElementsByTagName('list')
							).map(function (item) {
								return {
									title: item.getAttribute('title'),
									start: item.getAttribute('start')
								}
							})*/
							response.data.list
						);
					}
				}).catch(function (error) {
					CommonService.log('error', 'getCalendarData()::axios', error);
					failureCallback(error);
				});
			},
        },
		mounted() {
			this.calendar = this.$refs.calendar.getApi();
		},
		watch: {
			filters: {
				handler: function(value, oldValue) {
					CommonService.log('debug', 'watch:filters', {value: value, old_value: oldValue});
					this.calendar.refetchEvents();
				},
				deep: true
			},
		},
		computed: {
			filters() {
				return (typeof this.$props.inputFilters !== "undefined") ? this.$props.inputFilters : {};
			},
		}
    }
</script>

<style lang='scss'>
	@import '@/assets/styles/variables';

	.fc {
		max-width: 100% !important;
	}

	.fc-header-toolbar {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 0 !important;

		.fc-toolbar-title {
			font-family: $font_semi;
			font-weight: 600;
			font-size: 16px;
			line-height: 1.75rem;
			display: flex;
			text-align: center;
			align-items: center;
			padding: 6px 15px 10px 15px;
			padding-right: 70px;

			@media(max-width: 480px) {
				padding-right: 0;
			}

			color: $gray-900;
		}

		.fc-toolbar-chunk:first-child {
			padding: 15px 20px;

			@media(max-width: 480px) {
				padding: 10px;
				padding-right: 0;
			}

			.fc-button-group {

				.fc-prev-button,
				.fc-next-button {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 44px;
					height: 44px;
					background: $white;
					border: 1px solid $gray-300;
					box-sizing: border-box;
					border-radius: 12px;

					color: $gray-600;
				}

				.fc-prev-button {
					margin-right: 10px;
				}

				.fc-next-button {
					margin-right: 20px;
				}

				.fc-today-button {
					//border: 0;
					//background-color: transparent;
					font-family: $font_semi;
					font-weight: 600;
					font-size: 16px;
					line-height: 19px;
					display: flex;
					align-items: center;
					padding-left: 15px !important;
					padding-right: 15px !important;
					background: $white !important;
					border: 1px solid $gray-300 !important;
					box-sizing: border-box;
					border-radius: 12px !important;
					color: $gray-600;
				}
			}
		}

		.fc-toolbar-chunk:nth-child(2) {
			flex-grow: 1;
			padding: 0 10px;

			.fc-toolbar-title {
				display: block;
				font-size: 16px;
				line-height: 19px;
				margin: auto;
				text-align: center;
				&:first-letter {
					text-transform: capitalize;
				}
			}

			@media(max-width: 480px) {
				width: 100%;
			}
		}

		.fc-toolbar-chunk:last-child {
			width: 100%;
			padding: 10px;
			border-top: 1px solid  $gray-300;

			.fc-button-group {
				width: 100%;

				.fc-button {
					display: flex;
					align-items: center;
					justify-content: center;
					border: 0;
					background-color: transparent;
					font-family: $font_semi;
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 22px;
					display: flex;
					align-items: center;

					color: $gray-600;

					&-active {
						color: $gray-900;
					}

					@media(max-width: 480px) {
						font-size: 14px;
						line-height: 17px;
					}
				}
			}
		}
	}

	.fc-list {
		border: 0 !important;
	}

	.fc-list-day-cushion {
		padding: 10px 20px !important;
		background-color: $gray-300 !important;

		.fc-list-day-text {
			display: none;
		}

		.fc-list-day-side-text {
			font-family: $font_reg;
			font-weight: 400;
			float: left;
			font-size: 14px;
			line-height: 17px;
			display: flex;
			align-items: center;

			color: $gray-900;
		}
	}

	.fc-list-event {
		display: flex;
		align-items: center;
		padding: 20px;
		border-bottom: 1px solid $gray-300;

		td {
			border: 0 !important;
			padding: 0 !important;

			b {
				display: none;
			}


			@media(max-width: 480px) {
				i {
					font-size: 14px;
					line-height: 17px;
				}
			}
		}

		&-time {
			min-width: 90px !important;
			font-family: $font_semi;
			font-weight: 600;
			font-size: 16px;
			line-height: 19px;
			margin-right: 20px;

			color: $gray-900;
		}

		@media(max-width: 480px) {
			padding: 10px;
		}
	}

	.fc-list-event-graphic {
		display: flex;
		min-width: 16px !important;
		min-height: 16px !important;
		margin-right: 20px;
	}

	.fc-list-event {
		.fc-list-event-dot {
			border-radius: 50% !important;
			min-width: 14px !important;
			min-height: 14px !important;
			background: rgba(76, 175, 80, 0.4);
			border: 2px solid #4CAF50 !important;
		}


		&.moderation-event .fc-list-event-dot,
		&.sub-moderation .fc-list-event-dot,
		&.call-event .fc-list-event-dot,
		&.meet-event .fc-list-event-dot,
		&.show-event .fc-list-event-dot,
		&.deal-event .fc-list-event-dot,
		&.even-event .fc-list-event-dot,
		&.birthday-event .fc-list-event-dot {
			border-color: $gray-500 !important;
			background: rgba($gray-500, 0.4) !important;
		}
		&.moderation-event.is-new-event .fc-list-event-dot {
			border-color: $purple !important;
			background: rgba($purple, 0.4) !important;
		}
		&.sub-moderation.is-new-event .fc-list-event-dot {
			border-color: $purple !important;
			background: rgba($purple, 0.4) !important;
		}
		&.call-event.is-new-event .fc-list-event-dot {
			border-color: $primary !important;
			background: rgba($primary, 0.4) !important;
		}
		&.meet-event.is-new-event .fc-list-event-dot {
			border-color: $pink !important;
			background: rgba($pink, 0.4) !important;
		}
		&.show-event.is-new-event .fc-list-event-dot {
			border-color: $fire !important;
			background: rgba($fire, 0.4) !important;
		}
		&.deal-event.is-new-event .fc-list-event-dot {
			border-color: $raspberry !important;
			background: rgba($raspberry, 0.4) !important;
		}
		&.even-event.is-new-event .fc-list-event-dot {
			border-color: $ocean !important;
			background: rgba($ocean, 0.4) !important;
		}
		&.birthday-event.is-new-event .fc-list-event-dot {
			border-color: $lightred !important;
			background: rgba($lightred, 0.4) !important;
		}
	}


	.fc-list-event-data i {
		font-size: 16px;
		line-height: 22px;

		color: $gray-900;
	}

	.fc-list-event-title i{
		font-style: normal !important;
	}

	.fc-col-header {
		background-color: #F2F2F2;

		.fc-day {
			a {
				display: flex;
				align-items: center;
				min-height: 40px;
				width: 100%;
				padding-left: 10px;
				font-size: 14px;
				line-height: 17px;

				color: $gray-600;
			}
		}
	}

	.fc-daygrid {

		.fc-daygrid-day-number {
			font-family: $font_semi;
			font-weight: 600;
			font-size: 16px;
			line-height: 19px;
			width: 100%;
			padding-left: 10px;
			color: $gray-900;
		}

		.fc-daygrid-day {
			&.fc-day-today .fc-daygrid-day-frame {
				color: $fire;
				background-color: rgba($fire, 0.15);
			}
			&.fc-day-today .fc-daygrid-day-number {
				color: $fire;
			}
		}
	}

	.fc-day-sun {
		.fc-daygrid-day-number {
			color: $lightred;
		}
	}

	.fc-daygrid-day-frame {
		background-color: $white;
	}

	.fc-timegrid-event,
	.fc-daygrid-event {
		overflow: hidden;
		overflow-y: auto;
	}

	.fc-timegrid-event {
		border-top: 0;
		border-right: 0;
		border-bottom: 0;
		border-width: 3px;
		background-color: #F5F5F5;
		border-color: $lightred !important;

		.fc-event-main > span > span {

			display: flex;
			flex-direction: column;
			b {
				font-size: 14px;
				line-height: 17px;
				color: $gray-900;
			}

			i {
				font-size: 12px;
				line-height: 17px;
				font-style: normal;
				color: $gray-900;
			}
		}

		&.moderation-event,
		&.sub-moderation,
		&.call-event,
		&.meet-event,
		&.show-event,
		&.deal-event,
		&.even-event,
		&.birthday-event {
			border-color: $gray-700 !important;
			> span > span > i:before {
				border-color: $gray-500;
				background: rgba($gray-500, 0.4);
			}
		}
		&.moderation-event.is-new-event {
			border-color: $purple !important;
			> span > span > i:before {
				border-color: $purple;
				background: rgba($purple, 0.4);
			}
		}
		&.sub-moderation.is-new-event {
			border-color: $purple !important;
		}
		&.call-event.is-new-event {
			border-color: $primary !important;
		}
		&.meet-event.is-new-event {
			border-color: $pink !important;
		}
		&.show-event.is-new-event {
			border-color: $fire !important;
		}
		&.deal-event.is-new-event {
			border-color: $raspberry !important;
		}
		&.even-event.is-new-event {
			border-color: $ocean !important;
		}
		&.birthday-event.is-new-event {
			border-color: $lightred !important;
		}
	}

	.fc-daygrid-event.fc-daygrid-dot-event {
		&.moderation-event,
		&.sub-moderation,
		&.call-event,
		&.meet-event,
		&.show-event,
		&.deal-event,
		&.even-event,
		&.birthday-event {
			> span > span > i {
				color: $gray-700 !important;
				&:before {
					border-color: $gray-500 !important;
					background: rgba($gray-500, 0.4) !important;
				}
			}
		}
		&.moderation-event.is-new-event {
			color: $purple !important;
			> span > span > i {
				color: $purple !important;
				&:before {
					border-color: $purple !important;
					background: rgba($purple, 0.4) !important;
				}
			}
		}
		&.sub-moderation-even.is-new-event {
			> span > span > i {
				color: $gray-500 !important;
				&:before {
					border-color: $gray-500 !important;
					background: rgba($gray-500, 0.4) !important;
				}
			}
		}
		&.call-event.is-new-event {
			> span > span > i {
				color: $primary !important;
				&:before {
					border-color: $primary !important;
					background: rgba($primary, 0.4) !important;
				}
			}
		}
		&.meet-event.is-new-event {
			> span > span > i {
				color: $pink !important;
				&:before {
					border-color: $pink !important;
					background: rgba($pink, 0.4) !important;
				}
			}
		}
		&.show-event.is-new-event {
			> span > span > i {
				color: $fire !important;
				&:before {
					border-color: $fire !important;
					background: rgba($fire, 0.4) !important;
				}
			}
		}
		&.deal-event.is-new-event {
			> span > span > i {
				color: $raspberry !important;
				&:before {
					border-color: $raspberry !important;
					background: rgba($raspberry, 0.4) !important;
				}
			}
		}
		&.even-event.is-new-event {
			> span > span > i {
				color: $ocean !important;
				&:before {
					border-color: $ocean !important;
					background: rgba($ocean, 0.4) !important;
				}
			}
		}
		&.birthday-event.is-new-event {
			> span > span > i {
				color: $lightred !important;
				&:before {
					border-color: $lightred !important;
					background: rgba($lightred, 0.4) !important;
				}
			}
		}
	}

	.fc-timeGridWeek-view  {
		width: 100%;
		overflow: scroll hidden;

		.fc-scrollgrid {
			width: 1340px;
		}
	}

	.fc-view-harness {
		background-color: $white !important;
	}

	.fc-daygrid, .fc-timegrid {
		padding: 20px;
	}
    
    
    .fc-day-today {
        background-color: $white !important;
    }

    .fc-timegrid-event-harness {
        margin: 3px;
    }

    .fc-timegrid-body tr {
        height: 80px;

        .fc-timegrid-slot-label-frame {
            text-align: center;
        }
    }

    .fc-scrollgrid-section-header .fc-scrollgrid-sync-inner {
        background-color: #f2f2f2;
    }

    .fc-col-header-cell-cushion {
        justify-content: center;
        font-size: 14px;
        line-height: 17px;
        font-family: $font_reg;
        font-weight: 400;
        text-transform: capitalize;

        color: $gray-600;
    }

    .fc-timegrid-now-indicator-arrow {
        border-color: $primary !important;
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
    }

    .fc-timegrid-now-indicator-line {
        border-color: $primary !important;
    }

    .fc-daygrid-day-events {
        bottom: 3px !important;
    }

    .fc-daygrid-more-link {
        left: calc(100% - 30px) !important;
    }

    .fc-timegrid-more-link.fc-crm-more-link {
        width: 35px;
        height: 35px;
    }

    .fc-crm-more-link {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        line-height: 18px;
        width: 28px;
        height: 28px;
        border-radius: 8px 8px 0px 8px;
		background-color: $gray-500;
		color: $white;
		&.events-has-new {
			background-color: $primary;
			color: $white;
		}
		&.events-has-read {
			background-color: $warning;
			color: $white;
		}
		&.events-has-expiried {
			background-color: $danger;
			color: $white;
		}
    }

    a {
        cursor: pointer;
    }

    .fc-scroller::-webkit-scrollbar,
    .fc-timegrid::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #f2f2f2;
        border-radius: 0;
    }
      
    .fc-scroller::-webkit-scrollbar-thumb,
    .fc-timegrid::-webkit-scrollbar-thumb {
        background: $gray-300;
        border-radius: 0;
    }

	.fc-popover {
		z-index: 1054 !important;
		&.fc-more-popover {
			top: 50% !important;
			left: 50% !important;
			transform: translate(-50%, -50%);
			width: 100%;
			max-width: 480px;
			.fc-popover-body {
				padding: 0;
				overflow: hidden;
				overflow-y: auto;
				max-height: 410px;

				.fc-timegrid-event {
					padding: 10px 20px;
				}

				.fc-daygrid-event-harness {
					padding: 0;
					border-bottom: 1px solid #DEDEDE;
					a {
						display: flex;
						margin-left: 0;
						margin-right: 0;
						flex-flow: row;
						flex: 1;
						overflow-y: hidden;
						overflow-x: auto;
						padding: 15px 20px;
						&:hover, &:focus {
							background: $gray-100;
						}
						& > span {
							display: inline-flex;
							& > span {
								display: inline-flex;
								& > b {
									margin: auto 0px;
									font-family: $font_semi;
									font-weight: 600;
									font-size: 16px;
									line-height: 19px;
									width: 44px;
									margin-right: 20px;
									color: #222222;
								}
								& > i {
									position: relative;
									display: inline-block;
									word-break: normal;
									white-space: break-spaces;
									font-family: $font_reg;
									font-style: normal;
									font-size: 16px;
									line-height: 22px;
									color: #222222;
									padding-left: 35px;
									&:before {
										position: absolute;
										top: 50%;
										left: 0;
										transform: translateY(-50%);
										width: 14px;
										height: 14px;
										content: '';
										background: rgba(76, 175, 80, 0.4);
										border: 2px solid #4CAF50;
										border-radius: 50%;
									}
								}
							}
						}
					}
				}
			}
			.fc-popover-header {
				padding: 10px 20px;
				background-color: #DEDEDE;
			}
			.fc-popover-title {
				font-family: "Lato";
				font-weight: 400;
				float: left;
				font-size: 14px;
				line-height: 17px;
				display: flex;
				align-items: center;
				color: #222222;
			}
		}
	}

</style>

